export type SessionData = {
	replayId: string
	recordingStartTime: number
	trackingExpiryTime: number | undefined
}

export class SessionDataStore {
	key: string

	constructor(params: {slug: string}) {
		this.key = `zukoReplayData_${params.slug}`
	}

	getData(): SessionData | undefined {
		try {
			return JSON.parse(window.sessionStorage.getItem(this.key) || '{}')
			} catch (e) {
				return
			}
	}

	setData(data: SessionData): undefined {
		try {
			window.sessionStorage.setItem(this.key, JSON.stringify(data))
			} catch (e) {
				return
			}
	}

	deleteData(): undefined {
		try {
			window.sessionStorage.removeItem(this.key)
			} catch (e) {
				return
			}
	}

}
