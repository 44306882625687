import record from 'rrweb/lib/record/rrweb-record.cjs' // TODO: is this the best way to import ONLY the record function?
import { eventWithTime, listenerHandler } from '@rrweb/types'
import { generateReplayId } from './utils/generate-replay-id'
import { pageVisibilityListener } from './utils/listeners'
import { Logger } from './utils/logger'
import { SessionDataStore, SessionData } from './utils/session-data-store'
import { InitParams } from './index'
import RecordingStorage from './storage'

interface CustomEventDetail {
  slug: string;
  type: string;
}

declare global {
  interface DocumentEventMap {
    'trackingEvent': CustomEvent<CustomEventDetail>;
   }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Window { Zuko: any; }
}


/**
 * The time to wait to send the first payload - in milliseconds
 */
const FIRST_SEND_FREQUENCY = 1000

/**
 * The frequency of sending events to the back end EVENTS_URL - in milliseconds
 */
const SEND_FREQUENCY = 1000 * 3

/**
 * A large number of DOM mutations can negatively impact performance and cause issues in the replay pipeline.
 * We limit the total number of mutations in a recording. We will stop recording and prevent any further recording in that window.
 */
const MUTATION_LIMIT = 10000

/**
* We only want to record activity that the visitor is making themselves, so we set an inactivity bounday (in milliseconds) to
* avoid recording endless programatic mutations.
 */
const INACTIVITY_BOUNDARY = 8000

/**
 * Maximum duration span for a recording - in milliseconds
 */
export const MAX_RECORDING_DURATION = 1800000

/**
 * Gap of time over which a session will be deemed as abandoned - in milliseconds
 * Sessions are split based on the time being more than the INACTIVITY_GAP value
 * See the windower.splitWindow: https://gitlab.com/formisimo/zuko/form-analytics/event-processor/-/blob/master/lib/windower.js?ref_type=heads#L23
 */
export const INACTIVITY_GAP = 1800000

export class Record {
  state: 'NotRecording' | 'Recording' | 'Rejected'
  events: eventWithTime[]
  _replayId!: string
  _stopFn: listenerHandler | undefined
  _recordingStartTime!: number
  _eventsSendTimerId: ReturnType<typeof setTimeout> | undefined
  _eventsPausedTimerId: ReturnType<typeof setTimeout> | undefined
  _hasSetupWindowListeners: boolean // Page visibility listener
  logger: Logger
  slug: string
  domain: string
  hasSentFirstEvents!: boolean
  mutationCount!: number // TODO: prevent this number from being able to be edited outside of this code
  listeners!: listenerHandler[]
  _isProvisioningStorage!: Promise<void>
  _recordingStorage!: RecordingStorage | null
  _trackingExpiryTime: number | undefined // Analytics tracking will create a new session after this timestamp
  dataStore: SessionDataStore

  constructor(
    params: InitParams
	) {
    this.logger = new Logger({debug: params.debug})
    this.slug = params.slug
    this.domain = params.domain
    this.dataStore = new SessionDataStore({slug: params.slug})

    this._initialiseRecordingState()

    this._hasSetupWindowListeners = false // Listeners are setup once, on starting the recording for the first time in the window

    this.state = 'NotRecording'
    this.events = []
    this.listeners = []
    this._setupTrackingEventsListener()
  }

  _setStoredData() {
    this.dataStore.setData({
      replayId: this.replayId,
      recordingStartTime: this._recordingStartTime,
      trackingExpiryTime: this._trackingExpiryTime,
    })
  }

  _initialiseRecordingState() {
    this.logger.log(`Initialising RecordInstance for replayId: ${this.replayId}, and slug: ${this.slug}`)

    const storedData: SessionData | undefined = this.dataStore.getData()

    if (storedData?.replayId) {
      this.replayId = storedData.replayId
      this.logger.log(`Stored storedData.replayId being used: ${this.replayId}`)
    } else {
      this.replayId = generateReplayId()
    }

    if (storedData?.recordingStartTime) {
      this._recordingStartTime = storedData.recordingStartTime
      this.logger.log(`Stored storedData.recordingStartTime being used: ${this._recordingStartTime}`)
    } else {
      this._recordingStartTime = 0
    }

    if (storedData?.trackingExpiryTime) {
      this._trackingExpiryTime = storedData.trackingExpiryTime
      this.logger.log(`Stored storedData.trackingExpiryTime being used: ${this._trackingExpiryTime}`)
    } else {
      this._trackingExpiryTime = undefined
    }

    this._setStoredData()

    this.hasSentFirstEvents = false
    this.mutationCount = 0
    this._recordingStorage = null
    this._isProvisioningStorage = RecordingStorage
    .provision({formId: this.slug, domain: this.domain, replayId: this.replayId})
    .then((resp) => {
      this.logger.log(`Setup RecordingStorage for replayId: ${this.replayId}, and slug: ${this.slug}`)
      this._recordingStorage = resp
    })
    .catch((e) => {
      this.logger.error(e)
      this._reject()
    })
  }

  set replayId(replayId: string) {
    this._replayId = replayId
  }

  get replayId() {
    return this._replayId
  }

  getReplayIdForSession() {
    if (this.state === "Recording" && this.hasSentFirstEvents &&
      (this._trackingExpiryTime && (Date.now() <= this._trackingExpiryTime))
    ) {
      return this.replayId
    }
  }

  // Starts recording and saved the _stopFn on the instances
  _start() {
    this.logger.log(`Starting to record for replayId: ${this.replayId}`)

    // Exit if not a real user
    if (navigator?.userAgent?.includes('Googlebot') || navigator?.userAgent?.includes('AdsBot')) {
			return
		}

    try {
      // Start with a new set of events
      this.events = []

      // Start with a fresh timer
      if (this._eventsSendTimerId) {
        clearTimeout(this._eventsSendTimerId)
        this._eventsSendTimerId = undefined
      }

      this._eventsSendTimerId = setTimeout(() => {
        this._sendEvents()
      }, FIRST_SEND_FREQUENCY)

      const emit = (event: eventWithTime) => {
        // Keep a count of total mutations in order to reject the recording if mutation limit is surpassed
        if (event?.type === 3 && event?.data?.source === 0) {
          this.mutationCount +=1
        }
        if (this.mutationCount >= MUTATION_LIMIT) {
          this.logger.log(`Rejecting recording due to mutation limit reached: ${this.replayId}`)
          this._reject({sendFinalEvents: true})
        }

        // Freeze recording if there is a period of inactivity
        if (!(event.type === 3 && event.data.source === 0)) {
          if (this._eventsPausedTimerId) {
              clearTimeout(this._eventsPausedTimerId);
          }
          this._eventsPausedTimerId = setTimeout(() => {
            this.logger.log(`Freezing page as no recent activtiy: ${this.replayId}`)
              record.freezePage();
          }, INACTIVITY_BOUNDARY);
      }

        this.events.push(event)
      }
      emit.bind(this)

      // Stop an existing recording before starting so that a new recording generates the snapshot
      if (this._stopFn) {
				this._stopFn()
				this._stopFn = undefined
			}

      this._stopFn = record({
          ignoreClass: 'zuko-ignore', // Only takes a string and requires the ignoreSelector to be set too. See source: https://github.com/rrweb-io/rrweb/blob/master/packages/rrweb/src/record/observer.ts#L448
          ignoreSelector: '.zuko-ignore',
          blockClass: /zuko-replay-block|rr-block|zuko-block|fs-exclude|fs-block|data-hj-suppress|ex-block|sentry-block|highlight-block|ph-no-capture/,
          maskTextClass: /rr-mask|zuko-mask|fs-mask|ex-mask|sentry-mask|highlight-mask/,
          emit,
          sampling: { // https://github.com/rrweb-io/rrweb/blob/master/docs/recipes/optimize-storage.md
            scroll: 300, // the interval of scrolling event. Default is 100
            media: 800 // the interval of media interaction event. Default is 500
          },
          maskAllInputs: true,
      })

      if (!this._recordingStartTime) {
        this._recordingStartTime = Date.now()
        this._setStoredData()
      }

      this.state = 'Recording'
      this._setupWindowListeners()
    } catch (e) {
      this.logger.error(`Error with _start ${e}`)
    }
  }

  startRecording() {
    if (this.state === 'NotRecording') {
      this._start()
    } else {
      this.logger.log(`Invalid state on request to start for replayId: ${this.replayId}, state: ${this.state}`)
    }
  }

  // Stops recording
  _stop() {
    this.logger.log(`Stopping for replayId: ${this.replayId}`)

    this.state = 'NotRecording'
    if (this._stopFn) {
			this._stopFn()
			this._stopFn = undefined
      this.logger.log(`Recording removed for replayId: ${this.replayId}`)
		}
  }

  // Stops all recording capability for this instance
  _reject({sendFinalEvents}: {sendFinalEvents?: boolean} = {}) {
    this.logger.log(`Rejecting all recording capability for replayId: ${this.replayId}`)

    this.state = 'Rejected'
    if (this._stopFn) {
      this._stopFn()
      this._stopFn = undefined
      this.logger.log(`Recording removed for replayId: ${this.replayId}`)
    }

    if (this._eventsSendTimerId) {
      // Send any final events before the timer is cleared
      if (sendFinalEvents) this._sendEvents()

      clearTimeout(this._eventsSendTimerId)
      this._eventsSendTimerId = undefined
    }

    // Remove window listeners to stop trying to post on a beacon
    this.listeners.forEach((stop) => stop())
  }

  // A call to stopRecording originates from a Zuko.COMPLETION_EVENT or direct call
  stopRecording() {
    if (this.state === 'Recording') {
      this._stop()
      this.dataStore.deleteData()

      // Send any final events before the replayId is reset
      if (this._eventsSendTimerId) {
        this._sendEvents()
        clearTimeout(this._eventsSendTimerId)
        this._eventsSendTimerId = undefined
      }

      this._initialiseRecordingState()
    }
  }

  // Pauses recording and event timer
  _pause() {
    this.logger.log(`Pausing for replayId: ${this.replayId}`)

    this.state = 'NotRecording'
    if (this._stopFn) {
      this._stopFn()
      this._stopFn = undefined
      this.logger.log(`Recording removed for replayId: ${this.replayId}`)
    }

    if (this._eventsSendTimerId) {
      clearTimeout(this._eventsSendTimerId)
      this._eventsSendTimerId = undefined
    }
  }

  // Send the events, slice off the events sent, and reset recording if max recording reached
  async _sendEvents() {
    const replayId = this.replayId; // Use the same replayId throughout the send operation
    this.logger.log(`Checking if have any events to send for replayId: ${replayId}`)
    try {
      const events: eventWithTime[] = [...this.events] // Use these events saved up to this point in time

      // Max recording length reached
      if (this._recordingStartTime &&
        (Date.now() - this._recordingStartTime) > MAX_RECORDING_DURATION
      ) {
          this.logger.log(`Max recording length reached for replayId: ${replayId}. So stop recording`)
          this._reject()
          return;
        }

      if (!events.length) return;

      if (!this._recordingStorage) await this._isProvisioningStorage;
      if (!this._recordingStorage) return;

      this.logger.log(`Sending events for replayId: ${replayId}`)
      const resp = await this._recordingStorage.saveEvents({events, logger: this.logger});

      if (resp?.ok === true) {
        this.hasSentFirstEvents = true
        this.logger.log(`Successfully saved events for replayId: ${this.replayId}`)

        this.events = this.events.slice(events.length)
      } else {
        this.logger.log(`Failed to save events: ${resp?.status} ${resp?.statusText}`)
        this._reject()
      }
    } catch (e) {
      this.logger.error(`Error with _sendEvents for replayId: ${this.replayId}: ${e}`)
    } finally {
      // Currently this recursive timeout continues to run in the background when a tab is not visible
      if (this.state === 'Recording') {
        if (this._eventsSendTimerId) {
          clearTimeout(this._eventsSendTimerId)
          this._eventsSendTimerId = undefined
        }
        this._eventsSendTimerId = setTimeout(() => {
          this._sendEvents()
        }, SEND_FREQUENCY)
      }
    }
  }

  // Resets the instance's replayId, and recording state
  _resetRecording() {
    const oldReplayId = this.replayId;
    this.logger.log(`Resetting recording for replayId: ${oldReplayId}`)

    this._stop()
    this.dataStore.deleteData()
    this._initialiseRecordingState()

    this.logger.log(`Recording reset for old replayId: ${oldReplayId}. New replayId: ${this.replayId}`)
	}

  _setupWindowListeners() {
    try {
      if (!this._hasSetupWindowListeners) {
        this.logger.log(`Setting up window listeners for replayId: ${this.replayId}`)
        this.listeners.push(pageVisibilityListener({
          onHidden: () => this._sendBeaconEvents(), // Send events when the page is no longer visible (closed/switching tabs)
          onVisible: () => this._start(), // Resart recording when the page is visible
        }))
        this._hasSetupWindowListeners = true
        this.logger.log(`Completed setting up window listeners for replayId: ${this.replayId}`)
      }
    } catch (e) {
      this.logger.error(`Error with _setupWindowListeners ${e}`)
    }
  }

  _setupTrackingEventsListener() {
    try {
      this.logger.log(`Setting up events listener for replayId: ${this.replayId}, and slug: ${this.slug}`)
      document.addEventListener('trackingEvent', (e: CustomEvent) => {
        const detail: CustomEventDetail = e.detail
        if (detail.slug === this.slug) {
          switch (detail.type) {
            case window.Zuko.COMPLETION_EVENT.type:
              this.stopRecording()
              break;
            case window.Zuko.FORM_VIEW_EVENT.type:
              // Ignore - not used to start recordings or to track last event time
              break;
            default: {
              // Determine if the tracking has surpassed the gap of inactivity - reset the recording if so
              const currentTime = Date.now()
              if (this._trackingExpiryTime && (currentTime > this._trackingExpiryTime)) {
                this.logger.log(`Tracking has surpassed the gap of inactivity for replayId: ${this.replayId}`)
                this._resetRecording()
              } else {
                if (this.state === 'NotRecording') {
                  this.startRecording()
                }
                this._trackingExpiryTime = currentTime + INACTIVITY_GAP
                this._setStoredData()
              }
            }
          }
        }
      });
    } catch (e) {
      this.logger.error(`Error with _setupEventsListener ${e}`)
    }
  }

  async _sendBeaconEvents() {
    if ('sendBeacon' in navigator) {
      const replayId = this.replayId; // Use the same replayId throughout the send operation
      this.logger.log(`Checking if have events to send via sendBeacon for replayId: ${replayId}`)
      try {
        const events: eventWithTime[] = [...this.events]
        if (!events.length) return

        if (!this._recordingStorage) await this._isProvisioningStorage;
        if (this._recordingStorage) this._recordingStorage.saveEventsBeacon({events, logger: this.logger});

        this._pause()

        this.logger.log(`Completed sending events via sendBeacon for replayId: ${replayId}`)
      } catch (e) {
        this.logger.error(`Error with _sendBeaconEvents ${e}`)
      }
    }
  }
}
